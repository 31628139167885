import * as React from 'react';

import { Homepage } from 'corigan';

import { useChangelogSource } from 'staticqueries';
import { useNewsSource } from 'staticqueries';

const PageContents: React.FC = () => {
  const changelog = useChangelogSource();
  const news = useNewsSource();

  return <Homepage changelog={changelog} news={news} />;
};
export default PageContents;
